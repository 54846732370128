const initialState = {
    'current_check': {}
};

function rootReducer(state = initialState, action) {
    let new_state = state;
    if (action.type === 'SET_CURRENT_CHECK') {
        new_state = Object.assign({}, state, {
            current_check: action.payload
        });
    } else if(action.type === 'SET_COMPANY') {
        new_state = Object.assign({}, state, {
            company: action.payload
        });
    }
    window.sessionStorage.setItem("state", JSON.stringify(new_state))
    return new_state;
};

export default rootReducer;