import React from 'react';
import './input.css';

class Input extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const label_class = this.props.label!="" ? "" : "hidden";
        return (
            <div className="form-group">
                <label className={label_class}>{this.props.placeholder}</label>
                <input type={this.props.type} className="form-control" name={this.props.name} placeholder={this.props.placeholder} onChange={this.props.change} value={this.props.value} />
            </div>
        )
    }
}

export default Input;