import React, { Suspense } from 'react';
import Navbar from '../shared/navbar'
import Sidebar from '../shared/sidebar'
import Footer from '../shared/footer'
import Login from '../components/login'
import {
  Route,
  withRouter
} from "react-router-dom";
import { connect } from 'react-redux';
import { setCompany } from "../actions/index";
import './App.css';

const Home = React.lazy(() => import('../components/home'));
const Map = React.lazy(() => import('../components/map'));
const History = React.lazy(() => import('../components/history'));
const AggiungiViaggio = React.lazy(() => import('../components/home/aggiungi'));
const Mezzi = React.lazy(() => import('../components/mezzi'));
const Users = React.lazy(() => import('../components/users'));
const UsersAdd = React.lazy(() => import('../components/users/aggiungi'));
const MezziAdd = React.lazy(() => import('../components/mezzi/aggiungi'));
const Checks = React.lazy(() => import('../components/checks'));
const CheckDetails = React.lazy(() => import('../components/checks/details'));
const Daily = React.lazy(() => import('../components/giornaliere'));
const DailyDetails = React.lazy(() => import('../components/giornaliere/details'));

const mapDispatchToProps = dispatch => {
  return {
      setCompany: (c) => dispatch(setCompany(c))
  }
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    //this.gestoreClick = this.gestoreClick.bind(this);
  }
  componentDidMount() {
    let self = this;
    window.firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        window.firebase.firestore().collection('users').doc(user.uid).get()
          .then(function(doc) {
              let user = doc.data();
              user.id = doc.id;
              console.log("Setting: " + user.company)
              self.props.setCompany(user.company)
              localStorage.setItem('user', JSON.stringify(user));
              self.props.history.push("/home");
          })
      } else {
        self.props.history.push("/");
      }
    });
  }
  render() {
    return (
      <div className="wrapper">
        <Suspense fallback={<div>Loading...</div>}>
          <Navbar />
          <Sidebar />
        
          <Route exact path="/" component={Login}/>
          <Route exact path="/home" component={Home}/>
          <Route exact path="/map" component={Map}/>
          <Route exact path="/history" component={History}/>
          <Route exact path="/add-trip" component={AggiungiViaggio}/>
          <Route exact path="/mezzi" component={Mezzi}/>
          <Route exact path="/autisti" component={Users}/>
          <Route exact path="/aggiungi-autista" component={UsersAdd}/>
          <Route exact path="/modifica-autista/:id" component={UsersAdd}/>
          <Route exact path="/aggiungi-mezzo" component={MezziAdd}/>
          <Route exact path="/checks" component={Checks}/>
          <Route exact path="/checks/:id" component={CheckDetails}/>
          <Route exact path="/daily" component={Daily}/>
          <Route exact path="/daily-details" component={DailyDetails}/>

          <Footer />
        </Suspense>
      </div>
    );
  }
}

export default withRouter(connect(null, mapDispatchToProps)(App));
