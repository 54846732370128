import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import './sidebar.css'

const mapStateToProps = (state) => {
    console.log(state)
    return { 
        uRole: state.company
    }
}

class Sidebar extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
      //this.gestoreClick = this.gestoreClick.bind(this);
    }
    render() {
        let logoUrl = 'https://firebasestorage.googleapis.com/v0/b/mytransport-246817.appspot.com/o/frontends%2FMbility%2F60.png?alt=media&token=7375bf3a-0a5a-4614-94ee-2e5ba568cf50';
        if(window.location.host=='gtp.mbility.app' || this.props.uRole=="GTP") {
            logoUrl = 'https://firebasestorage.googleapis.com/v0/b/mytransport-246817.appspot.com/o/frontends%2FGTP%2Flogo_gtp.png?alt=media&token=37da7904-9e63-4096-bd69-7c5c4c4520ad';
        } else if(window.location.host=='easy.mbility.app' || this.props.uRole=="EASY") {
            logoUrl = 'https://firebasestorage.googleapis.com/v0/b/mytransport-246817.appspot.com/o/frontends%2FEasy%2Flogo_cooperativa_easy.png.webp?alt=media&token=14e8eb0e-7940-4fe9-9499-a2795874b969';
        }
      return (
        <aside id="sidebar" className="main-sidebar sidebar-dark-primary elevation-4">
            <a href="/" className="brand-link">
                <img src={logoUrl} alt={this.props.uRole} className="brand-image img-circle elevation-3" />
                <span className="brand-text font-weight-light">Gestionale</span>
            </a>
            <div className="sidebar">
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        <li className="nav-item">
                            <Link to="/home"><i className="nav-icon fa fa-dashboard"></i><p>Dashboard</p></Link>
                        </li>     
                        <li className="nav-item">
                            <Link to="/map"><i className="nav-icon fa fa-map"></i><p>Mappa</p></Link>
                        </li>     
                        <li className="nav-item">
                            <Link to="/history"><i className="nav-icon fa fa-history"></i><p>Storico</p></Link>
                        </li>  
                        <li className="nav-item">
                            <Link to="/mezzi"><i className="nav-icon fa fa-truck"></i><p>Mezzi</p></Link>
                        </li>  
                        <li className="nav-item">
                            <Link to="/autisti"><i className="nav-icon fa fa-users"></i><p>Autisti</p></Link>
                        </li>  
                        <li className="nav-item">
                            <Link to="/checks"><i className="nav-icon fa fa-calendar"></i><p>Check giornalieri</p></Link>
                        </li>  
                        <li className="nav-item">
                            <Link to="/daily"><i className="nav-icon fa fa-refresh"></i><p>Viaggi giornalieri</p></Link>
                        </li>  
                    </ul>
                </nav>
            </div>
        </aside>
      );
    }
  }
  
  export default connect(mapStateToProps, null)(Sidebar);
  